import Api from '@/helpers/api/index.js'

export const checkPhoneExists = async (phoneObject) => {
    const response = await Api.post('/api/client/validation/phone-exists', phoneObject)
    return response
}

export const checkEmailExists = async (email) => {
    const response = await Api.post('/api/client/validation/email-exists', { email })
    return response
}
